import { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import '../../assets/css/formEdit.scss'
import { useForm } from 'react-hook-form'
import ClientsController from '../../controllers/ClientsController'
import SnackBar from '../Modals/SnackBar'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select
} from '@mui/material'
const EditClient = (props) => {
  const validatePayment = (payment) => {
    if (payment) {
      if (payment === 'card') {
        setIsPayment1(true)
      } else if (payment === 'credit') {
        setIsPayment2(true)
      }
    }
  }
  const setIsLocked = (data) => {
    if (data) {
      setLocked('false')
    } else {
      setLocked('activo')
    }
  }
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const { idCliente } = useParams()
  const [type, setType] = useState('')
  const [message, setMessage] = useState('')
  const history = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm()
  const [locked, setLocked] = useState('')
  const [isSuper, setIsSuper] = useState(false)
  const [isPayment1, setIsPayment1] = useState(false)
  const [isPayment2, setIsPayment2] = useState(false)
  const [isSameday, setIsSameday] = useState(false)
  const [isNextday, setIsNextday] = useState(false)
  const [isAuto, setIsAuto] = useState(false)
  const [isExpress, setIsExpress] = useState(false)
  const [isTopClient, setIsTopClient] = useState(false)
  const [isAPIClient, setIsAPIClient] = useState(false)
  const [asesora, setAsesora] = useState('')
  useEffect(() => {
    const load = async () => {
      try {
        const objectClient = await ClientsController.getClient(idCliente)
        setValue('firstName', objectClient?.user.name.first)
        setValue('lastName', objectClient?.user.name.last)
        setValue('email', objectClient?.user.email)
        setIsLocked(objectClient?.user.locked)
        setValue('address', objectClient?.address)
        setValue('city', objectClient?.city)
        setValue('companyName', objectClient?.company.name)
        setValue('companyRuc', objectClient?.company.ruc)
        setValue('phone', objectClient?.phone)
        setIsNextday(objectClient?.nextDay)
        setIsSameday(objectClient?.sameDay)
        setIsAuto(objectClient?.autoService)
        setIsExpress(objectClient?.express)
        setIsTopClient(objectClient?.clienteTop)
        setIsAPIClient(objectClient?.clienteAPI)
        setAsesora(objectClient?.asesora)
        objectClient?.paymentOptions.includes('card') ? setIsPayment1(true) : setIsPayment1(false)
        objectClient?.super === true ? setIsSuper(true) : setIsSuper(false)
        if (objectClient?.paymentOptions.length > 0) {
          validatePayment(objectClient?.paymentOptions[0])
        }
      } catch (err) {
        console.log(err)
        throw err
      }
    }
    load()
  }, [])

  // const [errorUpdate, setErrorUpdate] = useState(null);

  const handleChange = () => {
    if (locked === 'activo') {
      setLocked('false')
    } else {
      setLocked('activo')
    }
  }

  const handleNextday = () => {
    if (isNextday) {
      setIsNextday(false)
    } else {
      setIsNextday(true)
    }
  }
  const handleSameday = () => {
    if (isSameday) {
      setIsSameday(false)
    } else {
      setIsSameday(true)
    }
  }
  const handleAuto = () => {
    if (isAuto) {
      setIsAuto(false)
    } else {
      setIsAuto(true)
    }
  }

  const handleIsTopClient = () => {
    if (isTopClient) {
      setIsTopClient(false)
    } else {
      setIsTopClient(true)
    }
  }
  const handleIsAPIClient = () => {
    if (isAPIClient) {
      setIsAPIClient(false)
    } else {
      setIsAPIClient(true)
    }
  }
  const handleExpress = () => {
    if (isExpress) {
      setIsExpress(false)
    } else {
      setIsExpress(true)
    }
  }
  const handleChangeAsesora = (event) => {
    setAsesora(event.target.value)
  }
  const verificateEmptyValues = (data) => {
    Object.entries(data.user).forEach((element) => {
      if (!element[1] || element[1] === null || element[1] === '') {
        delete data.user[element[0]]
      }
    })
  }
  const sendPartner = async () => {
    // recodar poner formato que recibe la api
    if (!Object.keys(errors).length) {
      const payload = {
        id: idCliente,
        user: {
          name: {
            first: getValues('firstName'),
            last: getValues('lastName')
          },
          email: getValues('email'),
          locked: locked === 'activo' ? '' : locked,
          password: getValues('password')
        },
        address: getValues('address'),
        city: getValues('city'),
        company: {
          name: getValues('companyName'),
          ruc: getValues('companyRuc')
        },
        paymentOptions: [],
        phone: getValues('phone'),
        super: isSuper,
        sameDay: isSameday,
        nextDay: isNextday,
        autoService: isAuto,
        express: isExpress,
        clienteTop: isTopClient,
        clienteAPI: isAPIClient,
        asesora,
        cantOrdenes: 0
      }
      isPayment1 ? payload.paymentOptions.push('card') : payload.paymentOptions.push('credit')
      // si la contraseña no varia, no se envía el dato
      verificateEmptyValues(payload)
      try {
        await ClientsController.updateClient(payload)
        setOpen(true)
        setType('success')
        setMessage('Cliente editado exitosamente')
        setTimeout(() => {
          history('/dashboard/clientes')
        }, 2000)
      } catch (e) {
        // setErrorUpdate(e.response.data);
        setOpen(true)
        setType('error')
        setMessage('Error al editar')
      }
    }
  }
  const cancelButton = () => {
    history('/dashboard/clientes')
  }
  const handleSuperChange = () => {
    if (isSuper === true) {
      setIsSuper(false)
    } else {
      setIsSuper(true)
    }
  }
  return (
    <div>
      <form className='form__register' onSubmit={handleSubmit(sendPartner)}>
        <h1>Editar cliente</h1>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='firstName'>
                {isSuper ? 'Nombre completo' : 'Nombre'}
              </InputLabel>
              <TextField
                name='firstName'
                {...register('firstName', {
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[\u00F1A-Za-z _]*[\u00F1A-Za-z][\u00F1A-Za-z _]*$/,
                    message: 'Solo se permiten letras sin tildes'
                  }
                })}
                className='input__form'
                id='firstName'
                variant='outlined'
                error={Boolean(errors.firstName)}
                helperText={errors.firstName ? errors.firstName.message : ''}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='lastName'>
                {isSuper ? 'Nombre comercial' : 'Apellidos'}
              </InputLabel>
              <TextField
                name='lastName'
                id='lastName'
                {...register('lastName', {
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[\u00F1a-z\d\-_\s]+$/i,
                    message: 'Solo se permiten letras sin tildes'
                  }
                })}
                className='input__form'
                variant='outlined'
                error={Boolean(errors.lastName)}
                helperText={errors.lastName ? errors.lastName.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='phone'>
                Celular
              </InputLabel>
              <TextField
                name='phone'
                {...register('phone', {
                  required: 'Campo requerido',
                  minLength: {
                    value: 7,
                    message: 'Inserta al menos 7 dígitos'
                  },
                  maxLength: {
                    value: 9,
                    message: 'Se permite 9 dígitos cómo máximo'
                  },
                  validate: {
                    isANumber: (value) => !isNaN(value) || 'Solo se admiten números'
                  }
                })}
                className='input__form'
                id='phone'
                variant='outlined'
                error={Boolean(errors.phone)}
                helperText={errors.phone ? errors.phone.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='email'>
                Email
              </InputLabel>
              {/* considerar cambiar el patron a ^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$ */}
              <TextField
                name='email'
                {...register('email', {
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                    message: 'El email es inválido'
                  }
                })}
                className='input__form'
                id='email'
                variant='outlined'
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='password'>
                Nueva contraseña (opcional)
              </InputLabel>
              <TextField
                name='password'
                {...register('password', {
                  minLength: {
                    value: 6,
                    message: 'La contraseña no puede ser menor de 6 caracteres'
                  }
                })}
                className='input__form'
                id='password'
                variant='outlined'
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <div>
              <InputLabel className='label__input' htmlFor='address'>
                Dirección
              </InputLabel>
              <TextField
                name='address'
                {...register('address', { required: 'Campo requerido' })}
                variant='outlined'
                className='input__form'
                id='address'
                error={Boolean(errors.address)}
                helperText={errors.address ? errors.address.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='city'>
                Ciudad
              </InputLabel>
              <TextField
                name='city'
                {...register('city', { required: 'Campo requerido' })}
                variant='outlined'
                className='input__form'
                id='city'
                error={Boolean(errors.city)}
                helperText={errors.city ? errors.city.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl variant='outlined' style={{ width: '100%', marginTop: '28px' }}>
              <InputLabel className='label__input' htmlFor=''>
                Asesora
              </InputLabel>
              <Select name='asesora' value={asesora} onChange={handleChangeAsesora} labelWidth={70}>
                <MenuItem value={0}>Sin asignar</MenuItem>
                <MenuItem value={1}>Alessandra Norabuena</MenuItem>
                <MenuItem value={2}>Raisa Torres</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='companyName'>
                Nombre de la compañía
              </InputLabel>
              <TextField
                name='companyName'
                {...register('companyName', { required: 'Campo requerido' })}
                className='input__form'
                id='companyName'
                variant='outlined'
                error={Boolean(errors.companyName)}
                helperText={errors.companyName ? errors.companyName.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='companyRuc'>
                RUC
              </InputLabel>
              <TextField
                name='companyRuc'
                {...register('companyRuc', {
                  required: 'Campo requerido',
                  minLength: {
                    value: 8,
                    message: 'El numero de caracteres no puede ser menor de 8'
                  },
                  maxLength: {
                    value: 11,
                    message: 'El numero de caracteres no puede ser mayor de 11'
                  },
                  validate: {
                    IsANumber: (value) => !isNaN(value) || 'Solo se admiten números'
                  }
                })}
                className='input__form'
                id='companyRuc'
                variant='outlined'
                error={Boolean(errors.companyRuc)}
                helperText={errors.companyRuc ? errors.companyRuc.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4}>
            <div className='input__checkbox'>
              <InputLabel className='label__input' htmlFor='locked'>
                Estado del cliente
              </InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    type='checkbox'
                    name='locked'
                    value={false}
                    onChange={handleChange}
                    checked={locked !== 'activo'}
                    color='primary'
                  />
                }
                label='Bloqueado'
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <div className='input__checkbox'>
              <InputLabel className='label__input' htmlFor=''>
                Opciónes de pago
              </InputLabel>
              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(isPayment1)}
                      onChange={() => {
                        setIsPayment1(!isPayment1)
                        setIsPayment2(false)
                      }}
                      name='payment1'
                      color='primary'
                      value={isPayment1}
                    />
                  }
                  label='Tarjeta'
                />
                {isSuper && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='payment2'
                        value={isPayment2}
                        onChange={() => {
                          setIsPayment2(!isPayment2)
                          setIsPayment1(false)
                        }}
                        checked={Boolean(isPayment2)}
                        color='primary'
                      />
                    }
                    label='Crédito'
                  />
                )}
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className='input__checkbox'>
              <InputLabel className='label__input' htmlFor=''>
                Tipo de Cliente
              </InputLabel>
              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='topClient'
                      value={true}
                      onChange={handleIsTopClient}
                      checked={Boolean(isTopClient)}
                      color='primary'
                    />
                  }
                  label='Top'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='APIClient'
                      value={true}
                      onChange={handleIsAPIClient}
                      checked={Boolean(isAPIClient)}
                      color='primary'
                    />
                  }
                  label='API'
                />
              </FormGroup>
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid alignItems='center' container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <div className='input__checkbox'>
              <InputLabel className='label__input' htmlFor=''>
                Servicios disponibles
              </InputLabel>
              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='nextday'
                      value={true}
                      onChange={handleNextday}
                      checked={Boolean(isNextday)}
                      {...register}
                      color='primary'
                    />
                  }
                  label='NextDay'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='sameday'
                      value={true}
                      onChange={handleSameday}
                      checked={Boolean(isSameday)}
                      color='primary'
                    />
                  }
                  label='In House'
                />
                {/* TODO: descomentar cuando express sea lanzado a producción */}
                <FormControlLabel
                  control={
                    <Checkbox
                      name='express'
                      value={true}
                      onChange={handleExpress}
                      checked={Boolean(isExpress)}
                      color='primary'
                    />
                  }
                  label='Express'
                />
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className='input__checkbox'>
              <InputLabel className='label__input' htmlFor=''>
                Tipo de vehiculo
              </InputLabel>
              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='auto'
                      value={true}
                      onChange={handleAuto}
                      checked={Boolean(isAuto)}
                      {...register}
                      color='primary'
                    />
                  }
                  label='Auto'
                />
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className='input__checkbox'>
              <div>
                <InputLabel className='label__input' htmlFor='super'>
                  Tipo de Cliente
                </InputLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSuper === true}
                      onChange={handleSuperChange}
                      name='super'
                      color='primary'
                      value={true}
                    />
                  }
                  label='Empresa'
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className='button__div'>
          <button className='button__register' type='submit'>
            Editar
          </button>
          <Button className='button__form' variant='contained' onClick={cancelButton}>
            Cancelar
          </Button>
        </div>
        <SnackBar type={type} message={message} openValue={open} handleClose={handleClose} />
      </form>
    </div>
  )
}

export default EditClient
