import React, { useEffect, useState, useRef, useReducer } from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import './Order.scss'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { ordersSelector, statusSelector } from '../../selectors/OrdersSelectors'
import * as ordersActions from '../../actions/OrdersActions'
import moment from 'moment'
import 'moment/locale/es'
import Paginator from '../Modals/Paginator'
import SnackBar from '../Modals/SnackBar'
import SelectCustomer from './SelectCustomer'
import SelectRider from './SelectRider'
import Loader from '../Modals/Loader'
import Export from './Export'
import MiniDetail from './MiniDetail/MiniDetail'
import OrdersController from '../../controllers/OrdersController'
import OrderController from '../../controllers/OrderController'
// import useWebsocket from "../../useWebsocket";
import SearchIcon from '@mui/icons-material/Search'
import { Grid, Tooltip } from '@mui/material'
import BackspaceIcon from '@mui/icons-material/Backspace'
import * as partnersActions from '../../actions/PartnersActions'
import { selectOneOrder } from '../../assets/js/setters'
import ReplayIcon from '@mui/icons-material/Replay'
import { ORDERS_TABLE_HEADERS, CommonTable } from '../Common'
import { renderTable } from '../../assets/js/helpers'

const list = [
  { title: 'Todos', value: 'none' },
  { title: 'Activos', value: ['arrived', 'sending', 'confirmed', 'pending'] },
  { title: 'Completados', value: 'completed' },
  { title: 'Cancelados', value: 'canceled' }
]

const Orders = () => {
  // TODO: optimize this component (reducers, atomization, refactoring, etc...)
  const [orderForDetail, setOrderForDetail] = useState({})
  const [type, setType] = useState()
  const [message, setMessage] = useState()
  const [open, setOpen] = useState()
  const miniDetailRef = useRef()
  const handleClose = () => {
    setOpen(false)
  }
  const dispatch = useDispatch()
  const orders = useSelector(ordersSelector)
  const statusReducer = useSelector(statusSelector)
  const reducer = (state = { search: '' }, action) => {
    const { type, value } = action
    if (type === 'SET_SEARCH') return { ...state, search: value }
  }
  const [values, dispatchSearch] = useReducer(reducer, { search: '' })
  const [status, setStatus] = useState(statusReducer)
  const [service, setService] = useState('')
  const DATE_NOW = new Date()
  const startdate = moment(DATE_NOW)
  const pastdate = moment(DATE_NOW)
  startdate.subtract(1, 'd')
  pastdate.subtract(2, 'd')
  const FORMAT_DATE = startdate.format('yyyy-MM-DD')
  const FORMAT_DATE_BEFORE = pastdate.format('yyyy-MM-DD')
  const [selectedDate, setSelectedDate] = useState('')
  const [page, setPage] = useState()
  const [payloadOnSubmit, setPayloadOnSubmit] = useState({
    action: '',
    status: '',
    createdAt: '',
    service: '',
    reserved: ''
  })
  const [payloadCustomer, setPayloadCustomer] = useState('')
  const [payloadPartner, setPayloadPartner] = useState('')
  /* TODO: Uncomment this when socket can be used again */
  // const websocket = useWebsocket(null);
  // Recorre el objeto y elimina los valores vacíos
  const deleteEmptyValues = (payload) => {
    Object.entries(payload).forEach((element) => {
      if (!element[1] || element[1] === 'none') {
        delete payload[element[0]]
      }
    })
  }
  // Set order for detail on list

  const handleFilterStatus = (event) => {
    if (event.target.value) {
      setStatus(event.target.value)
    }
  }

  const handleService = (event) => {
    if (event.target.value) {
      setService(event.target.value)
    }
  }

  const selectDate = (event) => {
    setSelectedDate(event.target.value)
  }

  const handleSearch = (event) => {
    dispatchSearch({ type: 'SET_SEARCH', value: event.target.value })
  }
  // go to detail in other tab
  // const goToDetail = (orderId) => window.open(`/${orderId}`, "_blank");

  const handleFilterbyId = () => {
    const payloadFinal = {
      page: 1,
      data: { _id: values.search }
    }

    if (values.search && !isNaN(Number(values.search))) {
      dispatch(ordersActions.getOrdersFilters({ payload: payloadFinal }))
    } else {
      setOpen(true)
      setType('error')
      setMessage('Inserta un valor numérico para buscar')
    }
  }

  // object for orders made yesterday (with pending action)
  const withStatusYesterday = {
    page: 1,
    data: {
      action: 'pending',
      createdAt: FORMAT_DATE
    }
  }

  // object for orders made two days ago (with pending action)
  const withStatusBeforeYesterday = {
    page: 1,
    data: {
      action: 'pending',
      createdAt: FORMAT_DATE_BEFORE
    }
  }

  // object for last orders
  const newState = {
    page: 1,
    data: {
      action: 'pending'
    }
  }
  // get a full detailed order
  const getFullOrder = async (idOrder) => await OrderController.getOrder({ id: idOrder })
  // set first order by default on detail
  const setFirstOrder = async () => {
    const orders = await OrdersController.getOrdersFilters({
      page: 1,
      data: { action: 'pending' }
    })
    if (orders.docs.length > 0) {
      const getFirstOrder = getFullOrder(orders.docs[0].id)
      setOrderForDetail(await getFirstOrder)
    }
  }

  const getDataOrders = () => {
    if (status) {
      if (status === 'yesterday') {
        setPayloadOnSubmit({
          action: 'pending',
          createdAt: FORMAT_DATE
        })
        deleteEmptyValues(payloadOnSubmit)
        dispatch(ordersActions.getOrdersFilters({ payload: withStatusYesterday }))
      } else if (status === 'beforeYesterday') {
        setPayloadOnSubmit({
          action: 'pending',
          createdAt: FORMAT_DATE_BEFORE
        })
        deleteEmptyValues(payloadOnSubmit)
        dispatch(ordersActions.getOrdersFilters({ payload: withStatusBeforeYesterday }))
      } else {
        dispatch(ordersActions.getOrdersFilters({ payload: newState }))
      }
    } else {
      dispatch(ordersActions.getOrdersFilters({ payload: newState }))
    }
    setPage(1)
    setFirstOrder()
  }
  useEffect(() => {
    getDataOrders()
  }, [])

  // filtra los estados
  const filterStatus = (state) => {
    if (state === 'none' || state === 'yesterday' || state === 'beforeYesterday' || !status) {
      return ''
    } else {
      return state
    }
  }
  // Envía los filtros
  const sendFilters = () => {
    setPayloadCustomer('')
    setPayloadPartner('')
    setPayloadOnSubmit({
      status: filterStatus(status),
      createdAt: status !== 'reserved' ? selectedDate : '',
      service: service === 'none' || !service ? '' : service,
      reserved: status === 'reserved' ? selectedDate : ''
    })
    deleteEmptyValues(payloadOnSubmit)
    const payloadFinal = {
      page: 1,
      data: {
        status: filterStatus(status),
        createdAt: status !== 'reserved' ? selectedDate : '',
        service,
        reserved: status === 'reserved' ? selectedDate : ''
      }
    }
    deleteEmptyValues(payloadFinal.data)
    Object.keys(payloadFinal.data).length <= 0
      ? dispatch(ordersActions.getOrdersFilters({ payload: newState }))
      : dispatch(ordersActions.getOrdersFilters({ payload: payloadFinal }))
    setPage(1)
  }
  // Función para traer el payload del hijo (payload de SelectCustomer)
  const payloadCustomerFunc = (data) => {
    setPayloadCustomer(data.data.customer)
    setPayloadPartner('')
    setPayloadOnSubmit('')
    setPage(1)
  }
  // Función para traer el payload del hijo (payload de SelectRider)
  const payloadPartnerFunc = (data) => {
    setPayloadPartner(data.data.partner)
    setPayloadCustomer('')
    setPayloadOnSubmit('')
    setPage(1)
  }

  // función para descartar los payload
  const payloadToSend = (payload, payloadClient, payloadRider) => {
    if (Object.keys(payload).length > 0 && payload) {
      return payload
    } else if (payloadClient) {
      return { customer: payloadClient }
    } else if (payloadRider) {
      return { partner: payloadRider }
    } else {
      return newState.data
    }
  }
  // Maneja el paginado
  const handleChangePage = (event, value) => {
    setPage(value)
    deleteEmptyValues(payloadOnSubmit)
    const payloadFinal = {
      page: value,
      data: payloadToSend(payloadOnSubmit, payloadCustomer, payloadPartner)
    }
    if (value <= orders?.totalPages) {
      dispatch(ordersActions.getOrdersFilters({ payload: payloadFinal }))
    }
  }

  const cleanFilters = () => {
    setPayloadOnSubmit(newState.data)
    setPage(1)
    dispatch(ordersActions.getOrdersFilters({ payload: newState }))
    setStatus('')
    setService('')
    setSelectedDate('')
  }

  // get partners
  useEffect(() => {
    const search = ''
    dispatch(partnersActions.getPartners({ payload: { page: 1, data: search } }))
  }, [dispatch])

  const setIdForMiniDetail = async (id) => setOrderForDetail(await selectOneOrder(id))
  return (
    <div className='orders__container'>
      <MiniDetail ref={miniDetailRef} data={orderForDetail} />
      <br />
      <div className='header'>
        <Grid className='first__form__filters' container spacing={1}>
          <Grid item xs={3}>
            <TextField
              select
              label='Estados'
              value={status}
              onChange={handleFilterStatus}
              variant='outlined'
              style={{
                width: '100%',
                height: '40px'
              }}
            >
              {list.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              label='Servicios'
              value={service}
              onChange={handleService}
              variant='outlined'
              style={{
                width: '100%',
                height: '40px'
              }}
            >
              <MenuItem value={'none'}>Todo</MenuItem>
              <MenuItem value={'programmed'}>Next Day</MenuItem>
              <MenuItem value={'sameday'}>In House</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='date'
              label='Fecha'
              type='date'
              variant='outlined'
              defaultValue='2017-05-24'
              style={{
                width: '100%',
                height: '40px'
              }}
              InputLabelProps={{
                shrink: true
              }}
              value={selectedDate}
              onChange={selectDate}
            />
          </Grid>
          <Grid style={{ display: 'flex' }} item xs={3}>
            <Tooltip title='Buscar por filtros' aria-label='add'>
              <IconButton
                variant='contained'
                size='small'
                style={{
                  backgroundColor: '#0a50e6',
                  color: 'white',
                  padding: '15px 7%',
                  borderRadius: '5px 0px 0px 5px'
                }}
                onClick={sendFilters}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Borrar filtros' aria-label='add'>
              <IconButton
                variant='contained'
                size='small'
                style={{
                  backgroundColor: '#f50057',
                  color: 'white',
                  padding: '15px 7%',
                  borderRadius: '0px 0px 0px 0px'
                }}
                onClick={cleanFilters}
              >
                <BackspaceIcon />
              </IconButton>
            </Tooltip>
            <Export />
            <Tooltip title='Actualizar pedidos' aria-label='add'>
              <IconButton
                variant='contained'
                size='small'
                style={{
                  backgroundColor: '#FFFF',
                  color: 'green',
                  padding: '15px 15px',
                  borderRadius: '50%',
                  marginLeft: '35px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                }}
                onClick={() => dispatch(ordersActions.getOrdersFilters({ payload: newState }))}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid className='second__form__filters' container spacing={1}>
          <Grid item xs={4}>
            <SelectCustomer payloadFunc={payloadCustomerFunc} />
          </Grid>
          <Grid item xs={4}>
            <SelectRider payloadFunc={payloadPartnerFunc} />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='outlined-basic'
              label='Buscar ID'
              variant='outlined'
              value={values.search}
              onChange={handleSearch}
              style={{ width: '100%', height: '40px' }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              variant='contained'
              size='small'
              style={{
                backgroundColor: '#0a50e6',
                color: 'white',
                padding: '15px 5%',
                borderRadius: '5px'
              }}
              onClick={handleFilterbyId}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div className='content__table'>
        {!orders ? (
          <Loader />
        ) : (
          renderTable(
            orders.docs.length,
            <CommonTable
              headers={ORDERS_TABLE_HEADERS}
              docs={orders?.docs}
              action={setIdForMiniDetail}
              type='order'
            />
          )
        )}
        <Paginator
          data={orders}
          payloadOnSubmit={payloadOnSubmit}
          page={page}
          handleChange={handleChangePage}
        />
      </div>
      <SnackBar type={type} message={message} openValue={open} handleClose={handleClose} />
    </div>
  )
}
export default Orders
