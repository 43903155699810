export const ORDER_STATUS_VALUES = {
  pending: { color: '#C4C4C4', value: 'Pendiente' },
  confirmed: { color: '#1976d2', value: 'Asignado' },
  sending: { color: 'rgba(183,121,31)', value: 'En Ruta' },
  canceled: { color: 'red', value: 'Cancelado' },
  completed: { color: 'green', value: 'Completado' },
  arrived: { color: 'rgba(183,121,31)', value: 'En Ruta' },
  reserved: { color: '#5D1F82', value: 'Reservado' }
}
export const fontColorByOrderStatus = (status) => (status === 'pending' ? 'black' : 'white')

export const FONT_COLOR_BY_SERVICE = {
  Nextday: 'black',
  Sameday: 'red',
  Express: '#1976d2'
}

export const PAYMENT_TYPE = {
  card: { color: '#ff8065', value: 'tarjeta' },
  credit: { color: '#83b2f5', value: 'crédito' }
}

export const ORDER_SERVICES_VALUES = {
  express: { color: '#A2E3E9', value: 'EXPRESS' },
  sameday: { color: '#C3AFFF', value: 'IN HOUSE' },
  nextday: { color: '#B4FF37', value: 'NEXTDAY' },
  samedaymercadoflex: { color: '#ffea2d', value: 'FLEX' }
}
export const ENUM_TABLE_HEADERS = {
  CHECK: 'Check',
  STATUS: 'Estado',
  ORDER: 'Orden',
  CREATED: 'Creado',
  ASIGNED: 'Asignado',
  ARRIVED: 'Llegó',
  FINISHED: 'Finalizó',
  PAY: 'Pago',
  BRAND: 'Marca',
  ORIGIN_CONTACT: 'Contacto Origen',
  SERVICE: 'Servicio',
  PARTNER: 'Driver',
  DETAIL: 'Detalle',
  RESERVERD: 'Reservado',
}

export const PARAMS_BASED_ON_HEADERS = {
  [ENUM_TABLE_HEADERS.ORIGIN_CONTACT]: 'customerOrigen',
  [ENUM_TABLE_HEADERS.BRAND]: 'customerMarca',
  [ENUM_TABLE_HEADERS.PARTNER]: 'partner',
  [ENUM_TABLE_HEADERS.STATUS]: 'status',
}

export const ORDERS_TABLE_HEADERS = [
  'Check',
  'Estado',
  'Orden',
  'Creado',
  'Asignado',
  'Llegó',
  'Finalizó',
  'Pago',
  'Marca',
  'Contacto Origen',
  'Servicio',
  'Driver',
  'Detalle'
]
export const EXPRESS_RESERVED_TABLE_HEADERS = [
  'Check',
  'Estado',
  'Orden',
  'Reservado',
  'Asignado',
  'Llegó',
  'Finalizó',
  'Pago',
  'Marca',
  'Servicio',
  'Driver',
  'Detalle'
]
export const EXPRESS_TABLE_HEADERS = [
  'Check',
  'Estado',
  'Orden',
  'Creado',
  'Asignado',
  'Llegó',
  'Finalizó',
  'Pago',
  'Marca',
  'Servicio',
  'Driver',
  'Detalle'
]

export const PARTNER_HEADERS = [
  'Registro',
  'Documento',
  'Apellidos',
  'Nombres',
  'Celular',
  'Email',
  'Vehículo',
  'App version',
  'Modelo',
  'Android version',
  'Editar'
]

export const CLIENT_HEADERS = [
  'Nombre Comercial',
  'Nombre',
  'Email',
  'Telefono',
  'Razon Social',
  'Ruc',
  'Registro',
  'Primer envío',
  'Último envío',
  'Nº de pedidos',
  'Asesora',
  'Editar'
]

export const AVISER_NUMBER_TO_NAME = {
  0: 'Sin asginar',
  1: 'Alessandra Norabuena',
  2: 'Raisa Torres'
}
