import React, { useEffect, useState, useReducer } from 'react'
import { NotificationManager } from 'react-notifications'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import getDistance from 'geolib/es/getDistance'
import LocationService from '../../services/LocationService'
import './Edit.scss'
import { useDispatch, useSelector } from 'react-redux'
import { orderSelector } from '../../selectors/OrderSelectors'
import * as orderActions from '../../actions/OrderActions'
import 'react-notifications/lib/notifications.css'
import { useForm } from 'react-hook-form'
import {
  caculateMaxDistance,
  originOrDestinationValidation,
  sameDayZoneValidation
} from './helpers'
import { getServiceZoneByPoint } from '../../utils/business'

const styles = (theme) => ({
  root: {
    margin: 20
  },
  closeButton: {
    position: 'absolute !important',
    right: '10px',
    top: '10px',
    color: 'grey'
  }
})
const handleWaitTime = (status, service) => status === 'completed' && service === 'express'
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle sx={{ margin: '20px 10px' }} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default function Edit ({ openEdit, handleClose, edit, point, index, orderStatus, service }) {
  // TODO -> Migrate from to react-form-hook
  const DEFAULT_FORM_VALUES = {
    geolocationValues: {
      address: point?.address || '',
      lat: point?.lat || '',
      lng: point?.lng
    },
    waitTime: point?.waitTime || '',
    description: point?.instructions || '',
    statePoint: point?.statePoint || '',
    subStatePoint: point?.subStatePoint || '',
    done: point?.done || ''
  }
  const reducer = (state = DEFAULT_FORM_VALUES, action) => {
    const { type, fieldName, value, payload } = action
    switch (type) {
      case 'SET_GEO_LOCATION':
        return { ...state, geolocationValues: payload }
      case 'SET_FORM_VALUES':
        return { ...state, [fieldName]: value }
      case 'RESET_MUTATED_POINT':
        return DEFAULT_FORM_VALUES
    }
  }
  const [mutatedPointForm, dispatchMutatedPointForm] = useReducer(reducer, DEFAULT_FORM_VALUES)
  const dispatch = useDispatch()
  const [placesValue, setPlacesValue] = useState(null)
  const draft = useSelector(orderSelector)

  const resetFormParams = () => {
    dispatchMutatedPointForm({
      type: 'RESET_MUTATED_POINT'
    })
    setPlacesValue(null)
    setValue('name', point?.contact?.name || '')
    setValue('phone', point?.contact?.phone || '')
    setValue('email', point?.contact?.mail || '')
  }
  const handleCloseModal = () => {
    resetFormParams()
    handleClose()
  }
  useEffect(() => {
    setValue('name', point?.contact?.name || '')
    setValue('phone', point?.contact?.phone || '')
    setValue('email', point?.contact?.mail || '')
    return resetFormParams()
  }, [point])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm()

  const handleChange = (event) => {
    dispatchMutatedPointForm({
      type: 'SET_FORM_VALUES',
      fieldName: event.target.name,
      value: event.target.value
    })
  }

  /**
   * It takes a new point, adds it to the draft order, calculates the max distance, and sends the order
   * to the server
   * @param newPoint - The new point to be added to the order.
   */
  const buildAndSendNewPoint = (newPoint) => {
    const newPointObject = {
      ...newPoint,
      address: mutatedPointForm.geolocationValues.address,
      contact: {
        name: getValues('name'),
        phone: getValues('phone'),
        mail: getValues('email')
      },
      instructions: mutatedPointForm.description,
      waitTime: mutatedPointForm.waitTime ? Number(mutatedPointForm.waitTime) : '',
      statePoint: mutatedPointForm.statePoint,
      subStatePoint: mutatedPointForm.subStatePoint,
      done: mutatedPointForm.done
    }
    const pointsToSend = originOrDestinationValidation(draft.points, newPointObject, index, edit)
    const origin = pointsToSend[0]
    const maxDistance = caculateMaxDistance(pointsToSend, origin, getDistance)
    const order = {
      id: draft._id,
      service: draft.service,
      vehicle: draft.vehicle,
      points: pointsToSend,
      customer: draft.customer,
      waitTimeTotal: 0
    }
    const verification = {
      card_id: '',
      distance: maxDistance
    }
    const payload = { order, verification }
    try {
      dispatch(orderActions.updatePoints({ payload }))
      NotificationManager.success(
        `Destino ${edit ? 'editado' : 'agregado'} exitosamente`,
        'Destino'
      )
      handleClose()
    } catch (err) {
      NotificationManager.error(
        'Hubo un error al editar o crear el pedido, contactaté con el equipo de IT',
        'Destino'
      )
    }
  }

  /**
   * It creates a new point object, gets the zone of that point, and if the zone is not the same as the
   * zone of the service, it builds and sends the new point
   */
  const createOrEditPointFunc = async () => {
    const newPoint = {
      lat: mutatedPointForm.geolocationValues.lat,
      lng: mutatedPointForm.geolocationValues.lng
    }
    const zone = await getServiceZoneByPoint(draft.service, newPoint)
    if (!zone) {
      NotificationManager.error('Punto fuera de cobertura', 'Zona')
      return
    }
    if (!sameDayZoneValidation(draft.service, zone)) {
      buildAndSendNewPoint(newPoint)
    } else {
      NotificationManager.error('In House solo admite zona 1 y zona 2', 'Zona')
    }
  }

  /**
   * It takes the place_id from the selected option in the dropdown, and uses it to get the address,
   * latitude, and longitude of the selected place
   */
  const setNewPointFunc = async (placesValue) => {
    if (placesValue) {
      const [result] = await LocationService.geocodeByPlaceId(placesValue.value.place_id)
      dispatchMutatedPointForm({
        type: 'SET_GEO_LOCATION',
        payload: {
          address: result?.formatted_address,
          lat: result?.geometry?.location?.lat(),
          lng: result?.geometry?.location?.lng()
        }
      })
    }
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={openEdit}
        maxWidth='lg'
      >
        <DialogTitle id='customized-dialog-title' onClose={handleCloseModal}>
          {edit ? 'Editar Destino' : 'Agregar Destino'}
        </DialogTitle>
        <div className='edit__content'>
          <form
            className='form'
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(createOrEditPointFunc)}
          >
            <div>
              <GooglePlacesAutocomplete
                onLoadFailed={(error) => console.error('Could not inject Google script', error)}
                selectProps={{
                  value: placesValue,
                  onChange: (newValue) => {
                    setPlacesValue(newValue)
                    setNewPointFunc(newValue)
                  },
                  styles: {
                    menu: (styles) => ({ ...styles, zIndex: 102 })
                  },
                  defaultInputValue: mutatedPointForm.geolocationValues.address
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['pe']
                  }
                }}
              />
            </div>
            <div className='form__content'>
              <TextField
                {...register('name', {
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[\u00F1A-Za-z _]*[\u00F1A-Za-z][\u00F1A-Za-z _]*$/,
                    message: 'Solo se permiten letras sin tildes'
                  }
                })}
                id='outlined-basic'
                label='Nombre del contacto'
                variant='outlined'
                className='text__field'
                name='name'
                error={errors.name}
                helperText={errors.name && errors.name.message}
              />
              <TextField
                {...register('phone', {
                  required: 'Campo requerido',
                  minLength: {
                    value: 7,
                    message: 'Inserta al menos 7 dígitos'
                  },
                  maxLength: {
                    value: 9,
                    message: 'Se permite 9 dígitos cómo máximo'
                  },
                  validate: {
                    isANumber: (value) => !isNaN(value) || 'Solo se admiten números'
                  }
                })}
                id='outlined-basic'
                label='Teléfono'
                variant='outlined'
                className='text__field'
                style={{ marginLeft: '10px' }}
                name='phone'
                error={errors.phone}
                type='number'
                helperText={errors.phone && errors.phone.message}
              />
              <TextField
                {...register('email', {
                  pattern: {
                    value: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                    message: 'El email es inválido'
                  }
                })}
                id='outlined-basic'
                label='Email del contacto'
                variant='outlined'
                className='text__field'
                style={{ marginLeft: '10px' }}
                name='email'
                type='email'
                error={errors.email}
                helperText={errors.email && errors.email.message}
              />
            </div>

            {orderStatus && service
              ? handleWaitTime(orderStatus, service) && (
              <div className='form__content'>
                <TextField
                  id='outlined-basic'
                  label='Tiempo de espera'
                  variant='outlined'
                  className='text__field'
                  name='waitTime'
                  type='number'
                  value={mutatedPointForm.waitTime}
                  onChange={handleChange}
                    />
              </div>
              )
              : ''}
            <div className='form__content'>
              <TextField
                id='outlined-basic'
                label='Instrucciones'
                variant='outlined'
                className='text__field'
                name='description'
                value={mutatedPointForm.description}
                onChange={handleChange}
              />
            </div>
            <div className='form__actions'>
              <Button
                type='submit'
                variant='contained'
                style={{ background: '#0a50e6', color: 'white' }}
              >
                Guardar
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  )
}
