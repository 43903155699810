import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import { useNavigate, Outlet, NavLink, Link } from 'react-router-dom'
import './header.scss'
import { useDispatch } from 'react-redux'
import * as loginActions from '../../actions/LoginActions'

export default function Header () {
  const dispatch = useDispatch()
  const history = useNavigate()
  const routes = {
    dashboard: { href: '/dashboard/inicio', viewName: 'Dashboard' },
    orders: { href: '/dashboard/pedidos', viewName: 'Pedidos' },
    express: { href: '/dashboard/express', viewName: 'Express' },
    mapRealtime: { href: '/dashboard/tiempo-real', viewName: 'Mapa' },
    partners: { href: '/dashboard/drivers', viewName: 'Drivers' },
    clientes: { href: '/dashboard/clientes', viewName: 'Clientes' },
    configuracion: { href: '/dashboard/configuracion', viewName: 'Configuración' }
    // facturacion: { href: 'https://admin.nirex.la/facturacion', viewName: 'Facturación' }
  }

  const logout = () => () => {
    localStorage.clear()
    dispatch(loginActions.setToken(''))
    history('/login')
  }
  const navOptionBasicStyle = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '14px',
    textDecoration: 'none'
  }
  const activeStyle = {
    ...navOptionBasicStyle,
    fontWeight: 'bold',
    fontSize: '16px'
  }

  return (
    <div>
      <AppBar position='fixed'>
        <Toolbar className='toolbar'>
          <div>
            <Link to='/'>
              <img
                src='/logo-blanco-sin-fondo.png'
                alt='logo directo'
                className='nirex__logo'
              />
            </Link>
          </div>
          <div className='links scroll-shadows'>
            {Object.values(routes).map(({ href, viewName }, index) =>
              viewName !== 'Facturación' ? (
                <NavLink
                  key={index}
                  to={href}
                  style={({ isActive }) => (isActive ? activeStyle : navOptionBasicStyle)}
                >
                  {viewName}
                </NavLink>
              ) : (
                <Button
                  key={index}
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    window.open(href, '_self')
                  }}
                  sx={{ ...navOptionBasicStyle, textTransform: 'capitalize' }}
                >
                  {viewName}
                </Button>
              )
            )}
            <Button
              variant='contained'
              sx={{
                backgroundColor: '#f50057',
                color: 'white'
              }}
              onClick={logout()}
            >
              Salir
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <div>
        <Outlet />
      </div>
    </div>
  )
}
