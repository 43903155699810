import { IconButton, Tooltip } from '@mui/material'
import { FilterNone, Launch, Star, DeveloperMode } from '@mui/icons-material'
import {
  fontColorByOrderStatus,
  ORDER_SERVICES_VALUES,
  ORDER_STATUS_VALUES,
  PAYMENT_TYPE
} from '../constants'
import { chipStyles, iconStyles } from './styles'
import ModalPartner from '../../Detail/ModalPartner'
import './styles.scss'

function padTo2Digits (num) {
  return String(num).padStart(2, '0')
}
/**
 * It takes a date in the format of a string and returns a string with the date and time in the format
 * of "day/month/year | hours:minutes"
 * @param date - The date you want to convert
 * @returns A string with the date and time
 */
export const getHoursAndDay = (date, onlyDay = false) => {
  const dateConverted = new Date(date)
  const hours = `${padTo2Digits(dateConverted.getHours())}:${padTo2Digits(
    dateConverted.getMinutes()
  )}`
  const day = `${dateConverted.getDate()}/${dateConverted.getMonth()}/${dateConverted.getFullYear()}`
  return onlyDay === false ? `${day} | ${hours}` : `${day}`
}

const IconContainer = ({ action, header }) => {
  return (
    <IconButton onClick={action}>
      {header === 'Check' ? <FilterNone sx={iconStyles} /> : <Launch sx={iconStyles} />}
    </IconButton>
  )
}

const ChipContainer = ({ orderStatus = '', orderService = '', vehicle = '' }) => {
  const getStatusValue = ORDER_STATUS_VALUES[orderStatus?.toLowerCase().split(' ').join('')]?.value
  const getServiceValue = ORDER_SERVICES_VALUES[orderService?.toLowerCase().split(' ').join('')]?.value
  const getStatusColor = ORDER_STATUS_VALUES[orderStatus?.toLowerCase().split(' ').join('')]?.color
  const getServiceColor = ORDER_SERVICES_VALUES[orderService?.toLowerCase().split(' ').join('')]?.color

  const vehicleName = {
    moto: 'MOTO',
    auto: 'AUTO/VAN'
  }[vehicle]

  return (
    <div
      style={{
        ...chipStyles,
        backgroundColor: orderStatus ? getStatusColor : getServiceColor,
        color: orderStatus ? fontColorByOrderStatus(orderStatus?.toLowerCase()) : 'black',
        border: orderService && `2px solid ${getServiceColor}`,
        textTransform: orderService && 'uppercase',
        fontWeight: orderService && 'bold'
      }}
    >
      {orderStatus && getStatusValue}
      {orderService && `${getServiceValue} ${vehicleName}`}
    </div>
  )
}

const FlatTextContainer = ({ action, children, textValue = '', isBold }) => {
  return (
    <div
      style={isBold && { fontWeight: 'bold' }}
      className={action && 'text__as__button'}
      onClick={action}
    >
      {textValue !== '' && textValue}
      {children}
    </div>
  )
}

const PaymentUsedContainer = ({ paymentValue }) => {
  return (
    <div
      style={{
        color: PAYMENT_TYPE[paymentValue].color,
        fontWeight: '500'
      }}
    >
      {PAYMENT_TYPE[paymentValue].value}
    </div>
  )
}

const AssignPartnerContainer = ({ orderId, orderStatus, partnerName }) => {
  const returnOnlyName = () => <>{partnerName ?? '-'}</>
  if (orderStatus === 'completed') return returnOnlyName()
  return <ModalPartner orderId={orderId} assigned={Boolean(partnerName)} rider={partnerName} />
}

const BrandContainer = ({ brandName, isClientTop, isApiClient }) => {
  return (
    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '2px' }}>
      <FlatTextContainer textValue={brandName} />

      {isClientTop && (
        <Tooltip title='Cliente Top'>
          <Star
            style={{
              marginTop: '-5px',
              color: '#FFC300'
            }}
          />
        </Tooltip>
      )}
      {isApiClient && (
        <Tooltip title='Cliente API'>
          <DeveloperMode
            style={{
              marginTop: '-5px',
              color: '#9BA0A4'
            }}
          />
        </Tooltip>
      )}
    </div>
  )
}

/**
 * It returns a component based on the header value
 * @param header - the header of the column
 * @param order - the order object
 * @param setOrderForDetail - This is a function that sets the order to be displayed in the detail
 * view.
 * @param selectOneOrder - a function that returns a promise that resolves to an order object
 * @returns A function that returns a component
 */
export const rowSwitcherForOrders = (header, order, action = () => console.log('no action')) => {
  const {
    status,
    service,
    reserved,
    date,
    dateAsigned,
    points,
    updatedAt,
    paymentUsed,
    customerMarca,
    clienteTop,
    clienteAPI,
    customerOrigen,
    partner,
    id,
    vehicle
  } = order

  const goToDetailAction = () => window.open(`pedidos/${id}`, '_blank')

  switch (header) {
    case 'Check':
      return <IconContainer header='Check' action={() => action(id)} />
    case 'Estado':
      return status && <ChipContainer orderStatus={status} />
    case 'Orden':
      return <FlatTextContainer textValue={id} action={() => action(id)} />
    case 'Creado':
      return <FlatTextContainer>{date ? getHoursAndDay(date) : '-'}</FlatTextContainer>
    case 'Reservado':
      return <FlatTextContainer>{reserved ? getHoursAndDay(reserved) : '-'}</FlatTextContainer>
    case 'Asignado':
      return (
        <FlatTextContainer>{dateAsigned ? getHoursAndDay(dateAsigned) : '-'}</FlatTextContainer>
      )
    case 'Llegó':
      return (
        <FlatTextContainer>
          {points && points.length > 0 && points[0]?.done ? getHoursAndDay(points[0]?.done) : '-'}
        </FlatTextContainer>
      )
    case 'Finalizó':
      return (
        <FlatTextContainer>
          {updatedAt && status === 'completed' ? getHoursAndDay(updatedAt) : '-'}
        </FlatTextContainer>
      )
    case 'Pago':
      return <PaymentUsedContainer paymentValue={paymentUsed} />
    case 'Marca':
      return (
        <BrandContainer
          brandName={customerMarca}
          isApiClient={clienteAPI}
          isClientTop={clienteTop}
        />
      )
    case 'Contacto Origen':
      return <FlatTextContainer textValue={customerOrigen} />
    case 'Servicio':
      return service && <ChipContainer orderService={service} vehicle={vehicle} />
    case 'Driver':
      return <AssignPartnerContainer orderId={id} orderStatus={status} partnerName={partner} />
    case 'Detalle':
      return <IconContainer header='Detalle' action={goToDetailAction} />
  }
}

export const rowSwitcherForClients = (header, client, action = () => console.log('no action')) => {
  const { user, phone, company, createdAt, firstOrderDate, lastOrder, cantOrdenes, asesora, id } =
    client

  switch (header) {
    case 'Nombre Comercial':
      return user && <FlatTextContainer>{user.name.last}</FlatTextContainer>
    case 'Nombre':
      return user && <FlatTextContainer>{user.name.first}</FlatTextContainer>
    case 'Email':
      return user && <FlatTextContainer>{user.email}</FlatTextContainer>
    case 'Telefono':
      return phone && <FlatTextContainer>{phone}</FlatTextContainer>
    case 'Razon Social':
      return company && <FlatTextContainer>{company.name}</FlatTextContainer>
    case 'Ruc':
      return company && <FlatTextContainer>{company.ruc}</FlatTextContainer>
    case 'Registro':
      return createdAt && <FlatTextContainer>{getHoursAndDay(createdAt, true)}</FlatTextContainer>
    case 'Primer envío':
      return (
        firstOrderDate && (
          <FlatTextContainer>{getHoursAndDay(firstOrderDate, true)}</FlatTextContainer>
        )
      )
    case 'Último envío':
      return lastOrder && <FlatTextContainer>{getHoursAndDay(lastOrder, true)}</FlatTextContainer>
    case 'Nº de pedidos':
      return cantOrdenes && <FlatTextContainer>{cantOrdenes}</FlatTextContainer>
    case 'Asesora':
      return asesora && <FlatTextContainer isBold={asesora > 0}>{asesora}</FlatTextContainer>
    case 'Editar':
      return <IconContainer header='Detalle' action={() => action(id)} />
  }
}

export const rowSwitcherForPartners = (
  header,
  partner,
  action = () => console.log('no action')
) => {
  const {
    createdAt,
    documentNumber,
    user,
    phone,
    vehicle,
    currentVersion,
    model,
    versionAndroid,
    id
  } = partner

  const goToDetailAction = () => window.open(`drivers/editar/${id}`, '_blank')

  switch (header) {
    case 'Registro':
      return createdAt && <FlatTextContainer>{getHoursAndDay(createdAt, true)}</FlatTextContainer>
    case 'Documento':
      return documentNumber && <FlatTextContainer>{documentNumber}</FlatTextContainer>
    case 'Apellidos':
      return user && <FlatTextContainer>{user.name.last}</FlatTextContainer>
    case 'Nombres':
      return user && <FlatTextContainer>{user.name.first}</FlatTextContainer>
    case 'Celular':
      return phone && <FlatTextContainer>{phone}</FlatTextContainer>
    case 'Email':
      return user && <FlatTextContainer>{partner.user.email}</FlatTextContainer>
    case 'Vehículo':
      return vehicle && <FlatTextContainer>{vehicle}</FlatTextContainer>
    case 'App version':
      return currentVersion && <FlatTextContainer>{currentVersion}</FlatTextContainer>
    case 'Modelo':
      return model && <FlatTextContainer>{model}</FlatTextContainer>
    case 'Android version':
      return versionAndroid && <FlatTextContainer>{versionAndroid}</FlatTextContainer>
    case 'Editar':
      return <IconContainer header='Detalle' action={goToDetailAction} />
  }
}
